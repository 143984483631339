<template>
    <b-modal :ref="modalRef" :title="livre ? ('Édition de ' + livre.nom) : 'Création d\' un livre'"
             hide-footer @hidden="destroyModal" size="lg">
        <div class="text-center">
            <b-form @submit.prevent="submit">
                <b-col lg="6" offset-lg="3">
                    <label v-b-tooltip.hover="'Carrée, maximum 1Mo, optionnelle'">
                        Image
                        <font-awesome-icon icon="question-circle"/>
                    </label>
                    <uploadable-picture :illustrated="livre" type="livres" v-model="uploadPicture"/>
                </b-col>
                <b-row>
                    <b-col lg="6">
                        <b-form-group label="Nom">
                            <b-form-input v-model="form.nom" type="text" required/>
                        </b-form-group>

                        <b-form-group label="Description">
                            <balloon-editor v-model="form.description"/>
                        </b-form-group>
                    </b-col>

                    <b-col lg="6">
                        <b-form-group label="Caution">
                            <b-input-group append="€">
                                <b-form-input v-model="form.caution" type="number" step="0.01" min="0.00"/>
                            </b-input-group>
                        </b-form-group>

                        <b-form-group label="Auteur">
                            <b-form-input v-model="form.auteur" type="text"/>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Tags">
                            <multi-select v-model="form.tags" :options="availableTags"
                                          :multiple="true" :taggable="true"
                                          :close-on-select="false"
                                          :clear-on-select="true"
                                          :preserve-search="false"
                                          track-by="id"
                                          @tag="addTag"/>
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" offset-lg="3">
                        <b-form-group label="Propriétaire">
                            <utilisateur-multiselect v-model="form.proprietaire" add-cluji-option/>
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" offset-lg="3">
                        <b-btn type="submit" variant="success" class="m-3" :disabled="loading">
                            <font-awesome-icon icon="check"/>
                            Valider
                            <font-awesome-icon icon="sync" spin v-if="loading"/>
                        </b-btn>
                    </b-col>
                </b-row>
            </b-form>
        </div>
    </b-modal>
</template>

<script>
    import {apiPath}           from '@/util/http';
    import modalMixin          from '@/mixin/modalMixin';
    import borrowableEditMixin from '@/mixin/borrowableEditMixin';

    const MultiSelect            = () => import('@/components/MultiSelect');
    const UploadablePicture      = () => import('@/components/UploadablePicture');
    const UtilisateurMultiselect = () => import('@/components/UtilisateurMultiselect');
    const BalloonEditor          = () => import('@/components/BalloonEditor');

    export default {
        name: "AdminEditLivreModal",
        components: {UploadablePicture, UtilisateurMultiselect, BalloonEditor, MultiSelect},
        mixins: [modalMixin, borrowableEditMixin],
        props: {
            livre: {
                type: Object,
                default: () => null
            },
            callback: {
                type: Function,
                required: true
            }
        },
        data: () => ({
            modalRef: 'adminEditLivreModal',
            uploadPicture: null,
            form: {
                nom: null,
                description: null,
                auteur: null,
                caution: null,
                proprietaire: null,
                tags: []
            },
            loading: false
        }),
        methods: {
            submit() {
                if (!this.loading) {
                    this.loading = true;
                    let formData = new FormData();
                    formData.append('picture', this.uploadPicture);
                    formData.append('form', JSON.stringify(this.form));
                    this.axios
                        .post(
                            apiPath('admin_edit_livre', this.livre ? {livre: this.livre.id} : {}),
                            formData,
                            {headers: {'Content-Type': 'multipart/form-data'}}
                        )
                        .then(() => this.$toaster.success('Livre ' + (this.livre ? 'modifié' : 'créé') + ' avec succès'))
                        .catch(() => this.$toaster.error("Impossible d'enregistrer le livre"))
                        .finally(() => {
                            this.loading = false;
                            this.callback();
                            this.hideModal();
                        });
                }
            }
        },
        mounted() {
            if (this.livre) {
                this.form = {...this.form, ...this.livre};
            }
        }
    }
</script>
